@import '~bootstrap';
@import '~@fortawesome/fontawesome-free/css/all.css';
@charset "UTF-8";
/*
Theme Name: GRUNDIG AKADEMIE 2020
Theme URI: http://www.grundig-akademie.com 
Author: Miya Ishihara
Author URI: http://www.grundig-akademie.com
Version: 0.1
*/
h1 {
  margin-top: 1rem !important;
}

h1,
h2,
h3 {
  color: #0065a3 !important;
}

a {
  color: #0085d6;
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline !important;
}

h1 {
  font-size: 2rem !important;
}

h2 {
  font-size: 1.5rem !important;
}

h3 {
  font-size: 1.25rem !important;
}

h4 {
  font-size: 1.1rem !important;
}

.text-ga-blue {
  color: #0065a3 !important;
}
.text-ga-blue-lighten {
  color: #a3dcff !important;
}
.text-ga-blue-darken {
  color: #00263d !important;
}
.text-ga-orange {
  color: #f07e26 !important;
}
.text-ga-orange-lighten {
  color: #f7b785 !important;
}
.text-ga-orange-darken {
  color: #a54e0b !important;
}

.text-primary{
  color: #0065a3 !important;
}

.btn-gray {
  font-weight: 500 !important;
  color: #00263d !important;
  background-color: #dee2e6 !important;
}
.btn-gray:hover {
  background-color: #6c757d !important;
  color: #fff !important;
  background-image: none;
  text-decoration: none !important;
}
.btn-gray:active {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #ced4da !important;
}
.btn-gray:focus {
  box-shadow: 0 0 0 0.2rem #adb5bd !important;
}
.btn-gray.active {
  color: #fff !important;
  background-color: #495057 !important;
  border-color: #ced4da !important;
  background-image: none;
  text-decoration: none;
}

.btn-outline-gray {
  font-weight: 500 !important;
  color: #0085d6 !important;
  border: 1px solid #dee2e6 !important;
}
.btn-outline-gray:hover {
  background-color: #0065a3;
  color: #fff !important;
  background-image: none;
  text-decoration: none !important;
}
.btn-outline-gray:active {
  color: #00263d !important;
  background-color: #dee2e6 !important;
  border-color: #ced4da !important;
}
.btn-outline-gray:focus {
  box-shadow: 0 0 0 0.2rem #adb5bd !important;
}

.btn-ga-blue {
  font-weight: 500 !important;
  color: #fff !important;
  background-color: #0065a3 !important;
}
.btn-ga-blue:hover {
  background-color: #0085d6 !important;
  color: #fff !important;
  background-image: none;
  text-decoration: none !important;
}
.btn-ga-blue:active {
  color: #fff !important;
  background-color: #0065a3 !important;
  border-color: #0085d6 !important;
}
.btn-ga-blue:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 101, 163, 0.6) !important;
}
.btn-ga-blue a {
  text-decoration: none;
}

.btn-outline-ga-blue {
  font-weight: 500 !important;
  color: #0065a3 !important;
  border: 1px solid #0065a3 !important;
}
.btn-outline-ga-blue:hover {
  background-color: #0065a3;
  color: #fff !important;
  background-image: none;
  text-decoration: none !important;
}
.btn-outline-ga-blue:active {
  color: #0065a3 !important;
  background-color: #fff !important;
  border-color: #0065a3 !important;
}
.btn-outline-ga-blue:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 101, 163, 0.6) !important;
}
.btn-outline-ga-blue a {
  text-decoration: none;
}
.btn-outline-ga-blue a:hover {
  background-color: #0065a3;
  color: #fff !important;
  background-image: none;
  text-decoration: none;
}

.btn-ga-blue-lighten {
  font-weight: 500 !important;
  color: #004570 !important;
  background-color: #d6efff !important;
}
.btn-ga-blue-lighten:hover {
  background-color: #a3dcff !important;
  color: #004570 !important;
  background-image: none;
  text-decoration: none !important;
}
.btn-ga-blue-lighten:active {
  color: #004570 !important;
  background-color: #d6efff !important;
  border-color: #a3dcff !important;
}
.btn-ga-blue-lighten:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 101, 163, 0.6) !important;
}

.btn-ga-orange {
  font-weight: 500 !important;
  color: #fff !important;
  background-color: #f07e26 !important;
}
.btn-ga-orange:hover {
  color: #753708 !important;
  background-color: #f7b785 !important;
  background-image: none;
  text-decoration: none !important;
}
.btn-ga-orange:active {
  color: #fff !important;
  background-color: #753708 !important;
  border-color: #f39a56 !important;
}
.btn-ga-orange:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 126, 38, 0.6) !important;
}

.btn-ga-orange-lighten {
  font-weight: 500 !important;
  color: #452105 !important;
  background-color: #fad3b5 !important;
}
.btn-ga-orange-lighten:hover {
  color: #452105 !important;
  background-color: #d4650f !important;
  background-image: none;
  text-decoration: none !important;
}
.btn-ga-orange-lighten:active {
  color: #452105;
  background-color: #fad3b5 !important;
  border-color: #f7b785 !important;
}
.btn-ga-orange-lighten:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 126, 38, 0.6) !important;
}

a.btn-arrow::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "";
  float: right;
}

#btn-contact {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  z-index: 4;
}

.bg-gray, .bg-gray-300 {
  background-color: #dee2e6 !important;
}
.bg-ga-orange-lighten-10 {
  background-color: #f39a56 !important;
}
.bg-ga-blue {
  background-color: #0065a3 !important;
}
.bg-ga-blue-transparent {
  background-image: linear-gradient(#0065a3, transparent) !important;
}
.bg-ga-orange {
  background-color: #f07e26 !important;
}
.bg-img-company-specific {
  background-image: url("https://www.grundig-akademie.de/cms/uploads/images/themen/home/firmenspezifische03.jpg");
  background-size: cover;
}

.border-ga-blue {
  border-color: #0065a3 !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: ">>" !important;
}

.breadcrumb {
  background-color: #fff !important;
}

.breadcrumb-item {
  font-weight: 600;
}

ul.breadcrumb {
  padding: 0;
}

label {
  font-weight: bold;
  color: #0065a3 !important;
}

input[type=checkbox],
input[type=radio] {
  transform: scale(1.5);
}

#newsletter {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.envelope {
  fill: #fff;
  width: 200px;
  height: 200px;
}

body {
  /* hyphens */
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.invisible {
  display: none !important;
}

a {
  color: #0065a3 !important;
}

.seminar-content h1 {
  font-size: 2rem;
}
.seminar-content h2 {
  font-size: 1.5rem;
}
.seminar-content h3 {
  font-size: 1.25rem;
}
.seminar-content h4 {
  font-size: 1.25rem;
}
.seminar-content ul {
  list-style: none;
  margin-left: -0.5rem;
}
.seminar-content ul li::before {
  content: "I";
  color: #f07e26;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.seminar-content p {
  margin-bottom: 0.5rem;
}
.seminar-content img {
  object-fit: contain;
  max-width: 100%;
}

#seminar-accordion img {
  object-fit: contain;
  max-width: 100%;
}

.logo-md {
  width: 200px;
}

.logo-taw {
  width: 75px;
}

.img-objectfit-cover {
  object-fit: cover;
}

.progress-btn-gray {
  font-weight: 500 !important;
  color: #00263d !important;
  background-color: #dee2e6 !important;
  cursor: default;
}
.progress-btn-gray.active {
  color: #fff !important;
  background-color: #495057 !important;
  border-color: #ced4da !important;
  background-image: none;
  text-decoration: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0065a3;
}

.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
  border-bottom: solid 2px #f07e26;
}

.navbar-expand-xl .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .first-nav a.nav-link {
    margin-left: 0;
  }
}

.navbar-nav .first-nav a {
  padding-left: 0 !important;
}

@media (min-width: 992px) {
  .navbar-nav .first .nav-link {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }
}
.navbar-nav .active, .navbar-nav a.trail {
  border-bottom: solid 2px #f07e26;
}

.navbar-dark .navbar-toggler {
  color: #fff !important;
  border-color: #fff !important;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #0065a3 !important;
}

.topic-menu a {
  background-color: #0065a3;
  text-decoration: none;
  font-weight: 700;
  color: #fff;
}
.topic-menu a img {
  min-height: 180px;
  max-height: 240px;
  width: 100%;
  object-fit: cover;
}
.topic-menu a span {
  min-height: 60px;
}
.topic-menu a:hover {
  background-color: #f07e26;
}

.list-group-ga-blue {
  font-weight: 500;
}
.list-group-ga-blue a:focus,
.list-group-ga-blue a:hover {
  z-index: 1;
  color: #fff !important;
  text-decoration: none;
  background-color: #0065a3 !important;
}
.list-group-ga-blue a::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "";
  float: right;
}
.list-group-ga-blue .list-group-item {
  border: 1px solid #0065a3;
}
.list-group-ga-blue .list-group-item.active {
  background-color: #0065a3;
  border-color: #0065a3;
}

.list-group-item.active {
  background-color: #0065a3 !important;
  border-color: #0065a3 !important;
}

.parent-link a {
  border-radius: 0.25rem;
  font-weight: 700;
  display: block;
  color: #0065a3 !important;
  background-color: #a3dcff !important;
  padding: 0.75rem 1.25rem;
}
.parent-link a:focus, .parent-link a:hover {
  color: #0065a3 !important;
  background-color: #3db5ff !important;
  text-decoration: none;
}

.list-link a {
  border-radius: 0.25rem;
  display: block;
  border: 1px solid #0065a3;
  color: #0065a3 !important;
  padding: 0.25rem 1.25rem;
  font-weight: 600;
}
.list-link a:focus, .list-link a:hover {
  color: #fff !important;
  background-color: #0065a3 !important;
  text-decoration: none;
}

.current-link a {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: 700;
  display: block;
  color: #fff !important;
  background-color: #0065a3 !important;
  padding: 0.75rem 1.25rem;
}
.current-link a:focus, .current-link a:hover {
  color: #fff !important;
  background-color: #0065a3 !important;
  text-decoration: none;
}

.list-group-item-ga-blue {
  color: #fff !important;
  background-color: #0065a3 !important;
}

.list-group-item-ga-blue:focus, .list-group-item-ga-blue:hover {
  color: #fff !important;
  background-color: #f07e26 !important;
}

#contact-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  z-index: 4;
}

.badge-ga-blue {
  color: #fff;
  background-color: #0065a3;
}

.card .card-header a {
  text-decoration: none;
}
.card .card-header a:hover {
  text-decoration: underline;
}
.card .card-body .card-title a {
  text-decoration: none;
}
.card .card-body .card-title a:hover {
  text-decoration: underline;
}
.card .card-body h2 a {
  color: #0065a3 !important;
}
.card .card-body ul {
  list-style: none;
  margin-left: -0.5rem;
}
.card .card-body ul li::before {
  content: "I";
  color: #f07e26;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.card-body .mb-3 ul {
  list-style: none;
  margin-left: -0.5rem;
}
.card-body .mb-3 ul li::before {
  content: "I";
  color: #f07e26;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.shortcut-img .image_container {
  height: 100%;
}
.shortcut-img .image_container .hyperlink_img {
  height: 100%;
}
.shortcut-img a {
  background-color: #0065a3 !important;
  color: #fff !important;
  display: block;
  font-weight: bold;
  text-decoration: none;
}
.shortcut-img a img {
  width: 100%;
  object-fit: cover;
  max-height: 180px;
}
.shortcut-img a:hover {
  color: #fff !important;
  background-color: #f07e26 !important;
  background-image: none;
  text-decoration: none;
}
.shortcut-img a:active {
  color: #fff !important;
  background-color: #753708 !important;
  border-color: #f39a56 !important;
}
.shortcut-img a:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 126, 38, 0.6) !important;
}
.shortcut-link a {
  background-color: #0065a3 !important;
  color: #fff !important;
  display: block;
  font-weight: bold;
}
.shortcut-link a:hover {
  color: #fff !important;
  background-color: #f07e26 !important;
  background-image: none;
  text-decoration: none;
}
.shortcut-link a:active {
  color: #fff !important;
  background-color: #753708 !important;
  border-color: #f39a56 !important;
}
.shortcut-link a:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 126, 38, 0.6) !important;
}

.max-100 {
  max-height: 100px;
}
.max-150 {
  max-height: 150px;
}
.max-170 {
  max-height: 170px;
}
.max-255 {
  max-height: 255px;
}
.max-275 {
  max-height: 275px;
}
.max-300 {
  max-height: 300px;
}
@media (min-width: 768px) {
  .max-md-255 {
    max-height: 255px;
  }
  .max-md-300 {
    max-height: 300px;
  }
}
@media (min-width: 1200px) {
  .max-lg-255 {
    max-height: 255px;
  }
  .max-lg-300 {
    max-height: 300px;
  }
}

.min-75 {
  min-height: 75px;
}
.min-100 {
  min-height: 100px;
}
.min-170 {
  min-height: 170px;
}
.min-300 {
  min-height: 300px;
}
@media (min-width: 768px) {
  .min-md-170 {
    min-height: 170px;
  }
  .min-md-400 {
    min-height: 400px;
  }
  .min-md-450 {
    min-height: 450px;
  }
  .min-md-550 {
    min-height: 550px;
  }
}
@media (min-width: 992px) {
  .min-lg-300 {
    min-height: 300px;
  }
  .min-lg-400 {
    min-height: 400px;
  }
  .min-lg-550 {
    min-height: 550px;
  }
}
@media (min-width: 1200px) {
  .min-xl-550 {
    min-height: 550px;
  }
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid #0065a3 !important;
}

.ce_text ul {
  list-style: none;
  margin-left: -0.5rem;
}
.ce_text ul li::before {
  content: "I";
  color: #f07e26;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.topic-img {
  height: 100%;
}
.topic-img figure {
  margin-bottom: 0px;
  height: 100%;
}
.topic-img figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .topic-img figure img {
    max-height: 250px;
  }
}

.w-100 figure {
  margin-bottom: 0;
  width: 100%;
  height: 100%;
}
.w-100 figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.max-img-100 figure img {
  max-height: 100px;
  object-fit: cover;
}
.max-img-150 figure img {
  max-height: 150px;
  object-fit: cover;
}
.max-img-200 figure img {
  max-height: 200px;
  object-fit: cover;
}
.max-img-255 figure img {
  max-height: 255px;
  object-fit: cover;
}

@media (max-width: 991px) {
  .max-md-255 figure img {
    max-height: 255px;
    object-fit: cover;
  }
}

@media (max-width: 991px) {
  .max-md-200 figure img {
    max-height: 200px;
    object-fit: cover;
  }
}

.max-300 figure img {
  max-height: 300px;
  object-fit: cover;
}
@media (max-width: 991px) {
  .max-300 figure img {
    max-height: 250px;
  }
}

.ce_image figure.image_container {
  margin: 0;
}

.ce_hyperlink figure.image_container {
  margin: 0;
}

.topic-link a {
  background-color: #0065a3 !important;
  color: #fff !important;
  display: block;
  padding: 1rem;
  font-weight: 700;
}
.topic-link a:focus, .topic-link a:hover {
  background-color: #f07e26 !important;
  color: #fff !important;
  text-decoration: none;
}

.slider-control {
  height: 40px;
}
.slider-control a,
.slider-control .slider-menu {
  top: 0px;
}

.slider-wrapper img {
  height: 100%;
  width: 100%;
}
